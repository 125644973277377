export default {
    ENVIRONMENT: "development",
    ENVIRONMENTS: {
        production: {
            API_URL: "https://dev-api-connect.digitalrange.com",
            BASE_IMG_PATH: "https://s3.amazonaws.com/admin.digitalrange.dev"
        },
        development: {
            API_URL: "https://dev-api-connect.digitalrange.com",
            BASE_IMG_PATH: "https://s3.amazonaws.com/admin.digitalrange.dev"
        }
    },
};