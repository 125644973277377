import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./Modal.css";

const ResolveChatModal = (props) => {
    let screenWidth = window.screen.width;
    let isMobile = screenWidth <= 767 ? true : false;
    
    const { show, hide, classchat, title, text, btntext1, btnColor, onClickPA } = props;
    return (
        <section className="as-complete-sec closeCatch">
            <Modal
                show={show}
                onHide={hide}
                className={`${classchat} ${isMobile ? "as-complete-modal-mobile" : null} closeCatch`}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="closeCatch"> </Modal.Header>
                <Modal.Body className="closeCatch">
                    
                    <p className="closeCatch">{text}</p>
                </Modal.Body>
                <Modal.Footer className="closeCatch">
                    <div className=" w-100 text-center closeCatch">
                        <Button variant="primary" onClick={onClickPA} style={{backgroundColor: btnColor}}>
                            {btntext1}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </section>
    );
};
export default ResolveChatModal;