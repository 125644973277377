import Config from "../config";

class WebService {
    API_URL = Config.ENVIRONMENTS[Config.ENVIRONMENT].API_URL;
    BASE_IMG_PATH = Config.ENVIRONMENTS[Config.ENVIRONMENT].BASE_IMG_PATH;

    async uploadMedia(formData, chatId, visitorId) {
        let body = new FormData();
        body.append("file", formData);

        return new Promise((resolve, reject) => {
            fetch(`${this.API_URL}/media/widget`, {
                method: "POST",
                credentials: "include",
                body,
                headers: {
                    // SwiftSalesVisitorId: visitorId
                }
            })
                .then((res) => res.json())
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    }

    async getAgentStatus(websiteId) {
        return new Promise((resolve, reject) => {
            fetch(`${this.API_URL}/webchat/agent/${websiteId}`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Authorization: "d45f3efa-0358-4f77-a4f5-287f5b44b33a"
                }
            })
                .then((res) => res.json())
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    }
}

export default WebService;
