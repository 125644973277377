export const getParams = () => {
    var queryParams = window.location.search
        .substr(1)
        .split("&")
        .reduce(function (q, query) {
            var chunks = query.split("=");
            var key = chunks[0];
            var value = chunks[1];
            return (q[key] = value) && q;
        }, {});

    return queryParams;
};
