export default {
    IframeLoaded: "iframe_loaded",
    Init: "init",
    WidgetOpen: "widget_open",
    WidgetClose: "widget_close",
    WidgetOffline: "widget_offline",
    GetState: "get_state",
    ShowChatForm: "show_chat_form",
    HideChatForm: "hide_chat_form",
    RenderMessage: "render_message",
    SendMessage: "send_message",
    TypingHint: "typing_hint",
    MessageLoading: "message_loading",
    EndConversation: "end_conversation",
    AgentJoined: "agent_joined",
    LoadMoreMessages: "load_more_messages",
    SubmitForm: "submit_form",
    EmailSendClick: "email_send_click",
    EmailSendSuccess: "email_send_success",
    EmailSendPending: "email_send_pending",
    EmailSendConfirm: "email_send_confirm",
    ChatResolved: "chat_resolved",
    StartChat: "start_chat",
    SetChatId: "set_chat_id",
    SetCookies: "set_cookies",
    ChatData: "chat_data",
    UpdateMessageId: "update_message_id",
    UpdateNewMessageCount: "update_new_message_count",
    GetAutomation: 'get_automation',
    AutomationFormSend: 'automation_form_send',
    AutomationMediaDownload: 'automation_media_download',
    ChatClose: 'chat_close',
    ListenForChatClose: 'listen_for_chat_close',
    AutomationFormAutoResponse: 'automationFormAutoResponse',
    GetWidgetSettings: 'get_widget_settings',
    ClickChatWidget: 'click_chat_widget',
    ShowAgentTyping: 'show_agent_typing',
    UserTyping: 'user_typing',
    AgentJoinedRange: 'joined_agent',
    ToggleIsWidgetOpened: 'toggle_is_widget_opened',
    PlayAudioOnNewMessage: 'play_audio_on_new_message',
    GetAutomationFormDetails: 'get_automation_form_details',
    TriggerGetAutomationFormDetails: 'trigger_get_automation_form_details',
    SetAgentOnlineStatus: 'set_agent_online_status',
    AutomationFormSubmitEver: 'automation_form_submit_ever',
};
