import React, { useContext, useState } from "react";
import complogo from "../../assets/images/profile.png";
import PostMessageContext from "../../context/PostMessageContext";
import ResolveChatModal from "../../component/Modal/ResolveChatModal";
import OnlineBadge from "../../assets/Svg-icons/Online/OnlineBadge.svg";
import { colorIsLight } from "../../utils/colorUtil";
import { concatBaseMediaUrl } from "../../utils/imageUtils";

const RCChatWidgetMainViewHeader = (props) => {
    const [show, setShow] = React.useState();
    const [chatResolveModal, setChatResolveModal] = useState(false);
    const {
        onChatClose,
        chatClosed,
        agentName,
        agentOnlineStatus,
        handleResolveChatModalToggle
    } = props;

    const { widgetSettings, websiteSettings } = useContext(PostMessageContext);

    const handleChatResolveModal = () => {
        setChatResolveModal(true);
        handleResolveChatModalToggle(true);
    };

    const handleChatClose = () => {
        onChatClose();
    };

    const showChatCloseDropdown = () => {
        document.getElementById("ChatCloseDropdown").classList.toggle("showChatClose");
    };

    window.onclick = function(event) {
        if (!chatClosed) {
            // if (!event.target.matches(".chatCloseDropbtn")) {
            let targetElem = event.target ? event.target.getAttribute("class") : null;
            if (!targetElem) {
                var dropdown = document.getElementById("ChatCloseDropdown");
                var openDropdown = dropdown;
                if (openDropdown.classList.contains("showChatClose")) {
                    openDropdown.classList.remove("showChatClose");
                }
                setChatResolveModal(false);
                handleResolveChatModalToggle(false);
            } else {
                if (!targetElem.includes("chatCloseDropbtn")) {
                    var dropdown = document.getElementById("ChatCloseDropdown");
                    var openDropdown = dropdown;
                    if (openDropdown.classList.contains("showChatClose")) {
                        openDropdown.classList.remove("showChatClose");
                    }
                }
                if (!targetElem.includes("closeCatch") && !targetElem.includes("modal-content")) {
                    setChatResolveModal(false);
                    handleResolveChatModalToggle(false);
                }
            }
        }
    };

    return (
        <div
            className="chat-box-header"
            style={{
                background: `linear-gradient(135deg, ${widgetSettings.BackgroundColor} 0%, ${widgetSettings.BackgroundColor} 100%)`,
            }}
        >
            <figure>
                <div className="logo-container">
                    <span
                        className={`status-indicator ${
                            agentOnlineStatus ? "status-online" : "status-offline"
                        }`}
                    />
                    <div className="chat-profile">
                        <img src={websiteSettings?.Company?.Logo?.ThumbPath ? concatBaseMediaUrl(websiteSettings?.Company?.Logo?.ThumbPath): null} alt="logo" />
                    </div>
                </div>
                <figcaption>
                    {/* <h2>BraeBurn CC</h2> */}
                    <h2 style={{ color: colorIsLight(widgetSettings?.BackgroundColor) ? "#000000" : "#ffffff" }}>{websiteSettings.WebsiteName}</h2>
                    {agentName && !(widgetSettings?.TakeOperatorName == "No") ? (
                        <p style={{ color: colorIsLight(widgetSettings?.BackgroundColor) ? "#000000" : "#ffffff" }}>
                            {!(widgetSettings.TakeOperatorName == "No") ? agentName : null}{" "}
                            <span
                                className={`status ${
                                    agentOnlineStatus ? "status-online-text" : "status-offline-text"
                                }`}
                                style={{ margin: 0 }}
                            >
                                {agentOnlineStatus ? "(online)" : "(offline)"}{" "}
                            </span>
                        </p>
                    ) : null}
                </figcaption>
            </figure>
            {/* <button onClick={handleChatClose} disabled = {chatClosed}>Close Chat</button> */}
            {/* three dot menu */}
            {!chatClosed ? (
                <div className="chat-close-dropdown">
                    {/* three dots */}
                    {/* <ul className="chatCloseDropbtn chatCloseicon ChatClosebtn ChatCloseshowLeft" onClick={showChatCloseDropdown}>
      <li></li>
      <li></li>
      <li></li>
    </ul> */}
                    {/* menu */}
                    <div id="ChatCloseDropdown" className="chat-close-dropdown-content">
                        <a className="closeCatch" onClick={handleChatResolveModal}>
                            Close Chat
                        </a>
                        {chatResolveModal ? (
                            <ResolveChatModal
                                show={() => setChatResolveModal(true)}
                                hide={() => setChatResolveModal(false)}
                                classchat={"as-complete-modal"}
                                title={"Resolve Chat"}
                                text={"Are you sure?"}
                                btntext1={"Resolve Chat"}
                                btnColor={widgetSettings.ButtonColor}
                                onClickPA={() => {
                                    handleChatClose();
                                    setChatResolveModal(false);
                                    handleResolveChatModalToggle(false);
                                }}
                                className="closeCatch"
                            />
                        ) : null}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default RCChatWidgetMainViewHeader;

