import React from "react";

import "./Loader.css";

const Loader = (props) => {
    const { size = "md", color, imageLoader } = props;
    return (
        <div className={`sk-fading-circle ${imageLoader ? "sk-fading-circle-image-loader" : "sk-fading-circle" }  circle-${size} ${color}`}>
            <div className="sk-circle1 sk-circle"></div>
            <div className="sk-circle2 sk-circle"></div>
            <div className="sk-circle3 sk-circle"></div>
            <div className="sk-circle4 sk-circle"></div>
            <div className="sk-circle5 sk-circle"></div>
            <div className="sk-circle6 sk-circle"></div>
            <div className="sk-circle7 sk-circle"></div>
            <div className="sk-circle8 sk-circle"></div>
            <div className="sk-circle9 sk-circle"></div>
            <div className="sk-circle10 sk-circle"></div>
            <div className="sk-circle11 sk-circle"></div>
            <div className="sk-circle12 sk-circle"></div>
        </div>
    );
};
export default Loader;
