import React from "react";

const defaultValue = {
    IframePostMessage() {},
    widgetSettings: {},
    visitorData: {},
    websiteSettings: {}
};

export default React.createContext(defaultValue);
