import React from "react";
import reactDom from "react-dom";
import "./RCChatWidgetMainView.css";
import complogo from "../../assets/images/profile.png";
import { MdBreakfastDining, MdOutlineAttachFile } from "react-icons/md";
import { MdSend } from "react-icons/md";
import { FaCircle, FaComments, FaIdBadge } from "react-icons/fa";
import { GoChevronDown } from "react-icons/go";
import widgetIcon from "../../assets/Svg-icons/WidgetIcon/widgetIcon.png";
import {
    RCChatWidgetMainViewHeader,
    RCChatWidgetMainViewContent,
    RCChatWidgetMainViewChatField
} from "../RCChatWidgetMainView";
import PostMessageContext from "../../context/PostMessageContext";
import { getParams } from "../../utils/commonUtil";
import { scrollToBottom } from "../../utils/scrollUtils";
import { MessageSenderType, PostMessageType } from "../../constants";
import WebService from "../../service/webService";

class RCChatWidgetMainView extends React.Component {
    constructor(props) {
        super(props);

        this.scrollSnapshot = React.createRef(null);
        this.typingInterval = React.createRef(null);
        this.getAutomationForm = React.createRef(null);
        this.pollingRef = React.createRef(null);
        this.WebService = new WebService();

        this.state = {
            chatWidget: false,
            widgetIntegrated: false,
            showMenu: false,
            showEmailContainer: false,
            showEmailSuccessMessage: false,
            showEndConversationContainer: false,
            showOptionsContainer: false,
            chatButtonClass: "heartBeat",
            agentJoined: false,
            emailForm: false,
            chatResolved: false,
            agentsOffline: false,
            messageData: [],
            messageDataAvailable: true,
            agentData: {},
            chatData: {},
            typing: false,
            iframeParentUrl: "",
            iframeEnv: "",
            messageLoading: true,
            showDownButton: false,
            isNewMessage: false,
            newMessagesCount: 0,
            unseenMessages: 0,
            visitorId: null,
            chatId: null,
            widgetSettings: {
                // Appearance: {},
                // Behaviour: {}
            },
            show: false,
            hasMore: true,
            automation: {},
            chatClosed: false,
            unreadMessagesCount: 0,
            agentTyping: false,
            websiteSettings: {},
            agentName: "",
            agentOnlineStatus: 0,
            resolveChatModalToggle: false,
            showAutomationForm: false,
            automationFormSubmitEver: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.show !== this.state.show) {
            if (this.state.show) {
                this.IframePostMessage({
                    Message: PostMessageType.ToggleIsWidgetOpened,
                    Data: true
                });
            } else {
                this.IframePostMessage({
                    Message: PostMessageType.ToggleIsWidgetOpened,
                    Data: false
                });
            }
        }
        //for automation form
        if (this.getAutomationForm.current != 1) {
            if (this.state.messageData.length) {
                this.getAutomationForm.current = 1;
                this.IframePostMessage({
                    Message: PostMessageType.TriggerGetAutomationFormDetails,
                    Data: false
                });
            }
        }
    }

    componentDidMount() {
        let params = getParams();
        if (params) {
            const parentUrl = params["origin"];
            const iframeEnv = params["iframeEnv"];
            // const widgetIntegrated = params["widgetIntegrate"];
            this.setState(
                {
                    messageData: [],
                    iframeParentUrl: parentUrl,
                    iframeEnv
                    // widgetIntegrated: widgetIntegrated
                },
                () => {
                    this.IframePostMessage({ Message: PostMessageType.IframeLoaded });
                }
            );
        }
        this.pollingRef.current = setInterval(async () => {
            if (this.state.websiteSettings) {
                this.WebService.getAgentStatus(this.state.websiteSettings.Id).then(
                    (res) => {
                        this.setState({ agentOnlineStatus: res.data });
                    }
                );
            }
        }, 8000);
        window.addEventListener("message", this.widgetEvents, false);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.widgetEvents, false);
        clearInterval(this.pollingRef.current);
    }

    IframePostMessage = (object) => {
        object["iframeEnv"] = this.state.iframeEnv;
        object = JSON.stringify(object);
        window.parent.postMessage(object, this.state.iframeParentUrl);
    };

    widgetEvents = (event) => {
        if (event.origin !== this.state.iframeParentUrl) {
            return;
        }
        let data = event.data;
        if (typeof data === "string") {
            try {
                data = JSON.parse(data);
            } catch (err) {
                return;
            }
        }

        switch (data.Message) {
            // case PostMessageType.Init:
            //     this.initWidget(data.Data);
            //     break;
            // case PostMessageType.GetState:
            //     // this.sendWidgetState();
            //     break;
            // case PostMessageType.WidgetOpen:
            //     this.openChatWidget();
            //     break;
            // case PostMessageType.WidgetClose:
            //     this.closeChatWidget();
            //     break;
            // case PostMessageType.TypingHint:
            //     this.handleTypingHint(data.Data);
            //     break;
            // case PostMessageType.HideChatForm:
            //     this.hideChatForm();
            //     break;
            // case PostMessageType.ShowChatForm:
            //     this.showChatForm();
            //     break;
            // case PostMessageType.WidgetOffline:
            //     this.widgetOffline(data.Data);
            //     break;
            case PostMessageType.RenderMessage:
                console.log("iframeData", data);
                this.getMessage(data);
                this.showWidgetOnAgentMessage(data);
                break;
            case PostMessageType.MessageLoading:
                this.handleMessageLoading(data);
                break;
            // case PostMessageType.AgentJoined:
            //     this.handleAgentJoined(data);
            // break;
            // case PostMessageType.EmailSendSuccess:
            //     this.handleEmailSendSuccess();
            //     break;
            // case PostMessageType.EmailSendPending:
            //     this.handleEmailPending();
            //     break;
            // case PostMessageType.ChatResolved:
            //     this.handleChatResolved();
            //     break;
            //case PostMessageType.SetChatId:
            //  this.setChatId(data.Data);
            //     break;
            // case PostMessageType.SetCookies:
            //     this.setCookies(data.Data);
            //     break;
            // case PostMessageType.ChatData:
            //     this.setChatData(data.Data);
            //     break;
            case PostMessageType.UpdateMessageId:
                this.updateAcknowledgedMessageId(data.Data);
                break;
            // case PostMessageType.UpdateNewMessageCount:
            //     this.updateNewMessageCount(data.Data);
            //     break;
            case PostMessageType.GetAutomation:
                this.getAutomationDetails(data.Data);
                break;
            case PostMessageType.ListenForChatClose:
                this.listenForChatClose(data.Data);
                break;
            case PostMessageType.GetWidgetSettings:
                this.setState({ widgetSettings: data.Data.Settings });
                this.setState({ websiteSettings: data.Data });
                this.setState({ show: data.Data.IsWidgetOpened });
                if (data.Data.Agent) {
                    this.setState({ agentName: data.Data.Agent.Name });
                    this.setState({ agentOnlineStatus: data.Data.Agent.OnlineStatus });
                }
                break;
            case PostMessageType.ShowAgentTyping:
                this.handleAgentTyping();
                break;
            case PostMessageType.AgentJoinedRange:
                this.setState({ agentName: data.Data.Name });
                break;
            case PostMessageType.SetAgentOnlineStatus:
                this.setState({ agentOnlineStatus: data.Data.OnlineStatus });
                break;
            case PostMessageType.GetAutomationFormDetails:
                this.getAutomationFormDetails(data.Data);
                break;
            case PostMessageType.AutomationFormSubmitEver:
                if (data?.Data?.ifEverSubmit) {
                    this.setState({ automationFormSubmitEver: true });
                }
                break;
            default:
                break;
        }
    };

    handleResolveChatModalToggle = (data) => {
        this.setState({ resolveChatModalToggle: data });
    };

    handleAgentTyping = () => {
        if (this.typingInterval.current) {
            clearInterval(this.typingInterval.current);
        }
        this.setState({ agentTyping: true });
        this.typingInterval.current = setTimeout(() => {
            this.setState({ agentTyping: false });
        }, 2500);
    };

    handleUserTyping = () => {
        this.IframePostMessage({ Message: PostMessageType.UserTyping });
    };

    clickChatWidget = async () => {
        this.IframePostMessage({ Message: PostMessageType.ClickChatWidget });
        if (this.state.show) {
            const container = document.getElementById("swiftchat-scroll-div");
            // if (container.scrollHeight - container.scrollTop == container.clientHeight) {
            //     setTimeout(() => {
            //         scrollToBottom();
            //     }, 100);
            // }
            let subtractingResult = container.scrollHeight - container.scrollTop;
            if (
                subtractingResult - container.clientHeight == 0 ||
                (subtractingResult - container.clientHeight < 1 &&
                    subtractingResult - container.clientHeight > -1)
            ) {
                setTimeout(() => {
                    scrollToBottom();
                }, 100);
            }
        }
    };

    handleChatClose = () => {
        this.IframePostMessage({ Message: PostMessageType.ChatClose, Data: false });
    };

    listenForChatClose = (data) => {
        console.log("Chat close listened", data);
        if (data.UserDetails) {
            this.setState({ agentData: data.UserDetails });
        }
        this.setState({ chatClosed: true });
    };

    handleAutomationMediaDownload = (automationMediaObj) => {
        this.IframePostMessage({
            Message: PostMessageType.AutomationMediaDownload,
            Data: automationMediaObj
        });
    };

    handleAutomationFormSubmit = (formData) => {
        this.IframePostMessage({ Message: PostMessageType.AutomationFormSend, Data: formData });
        this.setState({ showOptionsContainer: false });
        this.setState({ automation: {} });
        this.setState({ showAutomationForm: false });
    };

    getAutomationFormDetails = (data) => {
        this.setState({ showAutomationForm: false });
        let automation = {
            Automation: data
        };
        this.setState({ automation: automation });
        if (data.TakeUsersName || data.TakeUsersEmail || data.TakeUsersPhone) {
            if (this.state.messageData.length) {
                if (this.state.messageData.find((msg) => msg.SenderType == 1)) {
                    try {
                        this.setState({ showOptionsContainer: true });
                    } catch (err) {
                        console.log("Error", err);
                    }
                } else {
                    this.setState({ showAutomationForm: true });
                }
            } else {
                this.setState({ showAutomationForm: true });
            }
        }
    };

    getAutomationDetails = (data) => {
        if (data.Automation.Automation) {
            this.setState({ show: true }, () => this.setState({ unreadMessagesCount: 0 }));
            if (document.visibilityState === "hidden") {
                this.IframePostMessage({ Message: PostMessageType.PlayAudioOnNewMessage });
            }
        }
    };

    showWidgetOnAgentMessage = (data) => {
        if (data.Data.SenderType == 2 && !this.state.show) {
            // this.setState({show: true})
            if (data.Data.IsAutomatedMessage == 1) {
                this.setState({ show: true }, () => this.setState({ unreadMessagesCount: 0 }));
            } else {
                this.setState((state) => ({ unreadMessagesCount: state.unreadMessagesCount + 1 }));
                this.IframePostMessage({ Message: PostMessageType.PlayAudioOnNewMessage });
            }
        }
        //if visibility hidden and widget opened
        if (data.Data.SenderType == 2 && this.state.show) {
            if (document.visibilityState === "hidden") {
                this.IframePostMessage({ Message: PostMessageType.PlayAudioOnNewMessage });
            }
        }
        // only for agentTyping false when agent sends message
        if (data.Data.SenderType == 2 && data.Data.IsAutomatedMessage != 1) {
            this.setState({ agentTyping: false });
        }
    };

    updateAcknowledgedMessageId = (data) => {
        const { messageData } = this.state;
        messageData.forEach((msg) => {
            if (msg.MessageUUID === data.MessageUUID) {
                msg["Id"] = data.MessageId;
            }
        });
        this.setState({ messageData });
    };

    handleMessageLoading = (data) => {
        this.setState({ messageLoading: data.Data });
    };

    getMessage = (data) => {
        const { messageData } = this.state;
        const { hasMore } = this.state;

        if (data.Data.atStart) {
            // console.log("if chal rha haii");
            if (Array.isArray(data.Data)) {
                if (data.Data.length) {
                    messageData.unshift(...data.Data);
                } else {
                    this.setState({ hasMore: false });
                }
            } else {
                messageData.unshift(data.Data);
                this.setState({ hasMore: true });
            }

            this.setState({ isNewMessage: false });
        } else {
            // console.log('else chal rha hai')
            if (Array.isArray(data.Data)) {
                if (data.Data.length) {
                    // console.log("else k under message data", messageData)
                    // console.log("else k under data.Data", data.Data)
                    messageData.push(...data.Data);
                } else {
                    this.setState({ hasMore: false });
                }
            } else {
                // console.log("else k under message data", messageData);
                // console.log("else k under data.Data", data.Data);
                messageData.unshift(data.Data);

                // this.setState({ hasMore: true });
            }
            this.setState({ isNewMessage: true });
            // if (this.state.showDownButton && data.Data.EventType === ChatMessageType.Message) {
            //     this.setState((state) => ({ unseenMessages: state.unseenMessages + 1 }));
            // }
        }
        if (hasMore) {
            this.setState({ messageData }, () => {
                if (this.scrollSnapshot.current) {
                    const container = document.getElementById("swiftchat-scroll-div");
                    container.scrollTop =
                        container.scrollHeight -
                        (this.scrollSnapshot.current.prevScrollHeight -
                            this.scrollSnapshot.current.prevScrollTop);
                }

                // if (
                //     !data.Data.atStart &&
                //     (data.Data.SenderType === MessageSenderType.Visitor ||
                //         !this.state.showDownButton)
                // ) {
                //     // console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR")
                //     // scrollToBottom();
                // }

                if (Array.isArray(data.Data)) {
                    if (data.pageCounter == 1 && data.Data.length != 0) {
                        scrollToBottom();
                    }
                }

                /* When new message sent*/
                if (!Array.isArray(data.Data)) {
                    scrollToBottom();
                }

                //console.log("messageData", messageData);
            });
        } else {
            /* When new message sent*/
            if (!Array.isArray(data.Data)) {
                scrollToBottom();
            }
        }
    };

    render() {
        const { show, resolveChatModalToggle } = this.state;
        const { messageLoading, hasMore } = this.state;
        const { showOptionsContainer } = this.state;
        const { showAutomationForm } = this.state;
        const { automation } = this.state;

        return (
            <PostMessageContext.Provider
                value={{
                    IframePostMessage: this.IframePostMessage,
                    widgetSettings: this.state.widgetSettings,
                    // visitorData: { chatId, visitorId },
                    websiteSettings: this.state.websiteSettings
                }}
            >
                <section className="chat-widget">
                    <div className={`chat-box-sec  nav ${!show ? "fadeOut" : "fadeIn"}  `}>
                        {/* {resolveChatModalToggle ? <div className="resolveChatModalBlur"></div> : null} */}
                        <RCChatWidgetMainViewHeader
                            onChatClose={this.handleChatClose}
                            chatClosed={this.state.chatClosed}
                            agentName={this.state.agentName}
                            agentOnlineStatus={this.state.agentOnlineStatus}
                            handleResolveChatModalToggle={(data) =>
                                this.handleResolveChatModalToggle(data)
                            }
                        />
                        <RCChatWidgetMainViewContent
                            messages={this.state.messageData}
                            messageLoading={messageLoading}
                            hasMore={hasMore}
                            scrollSnapshot={this.scrollSnapshot}
                            automation={this.state.automation}
                            showOptionsContainer={showOptionsContainer}
                            onAutomationFormSubmit={this.handleAutomationFormSubmit}
                            onAutomationMediaDownload={this.handleAutomationMediaDownload}
                            chatClosed={this.state.chatClosed}
                            agentData={this.state.agentData}
                            agentTyping={this.state.agentTyping}
                            handleUserTyping={this.handleUserTyping}
                            handleShowOptionsContainer={() => {
                                this.setState({ showOptionsContainer: true });
                            }}
                            showAutomationForm={showAutomationForm}
                            automationFormSubmitEver={this.state.automationFormSubmitEver}
                        />

                        {this.state.websiteSettings?.BrandedFooter ? (
                            <div
                                className="chat-box-footer"
                                style={{
                                    backgroundColor: this.state.widgetSettings.BackgroundColor
                                }}
                            >
                                POWERED BY <strong> CLUBCHAT</strong>
                            </div>
                        ) : null}
                    </div>
                    <button
                        style={{
                            background: `linear-gradient(135deg, ${this.state.widgetSettings.BackgroundColor} 0%, ${this.state.widgetSettings.BackgroundColor} 100%)`
                        }}
                        className="chat-widget-btn"
                        onClick={() => {
                            this.setState({ show: !show }, () => {
                                this.setState({ unreadMessagesCount: 0 });
                                this.clickChatWidget();
                            });
                        }}
                    >
                        {!show ? (
                            <img src={widgetIcon} className="widget-icon" />
                        ) : (
                            <GoChevronDown className="vertical-align-middle" />
                        )}
                        {/* {this.state.unreadMessagesCount ? 
                        ( <><div className="unseen-message-count-icon-wrapper" ><FaCircle  className="unseen-message-count-icon"/><span className="unseen-message-count">{this.state.unreadMessagesCount}</span></div> {" "} </>)
                        : null} */}
                        {this.state.unreadMessagesCount ? (
                            <>
                                <div className="unseen-message-count-icon-wrapper">
                                    {this.state.unreadMessagesCount}
                                </div>{" "}
                            </>
                        ) : null}
                    </button>
                </section>
            </PostMessageContext.Provider>
        );
    }
}

export default RCChatWidgetMainView;
