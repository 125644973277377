import React from "react";
import { MdOutlineAttachFile } from "react-icons/md";
import { MdSend } from "react-icons/md";
import { PostMessageType, MediaType, AllowedFileType, AllowedImageType } from "../../constants";
import PostMessageContext from "../../context/PostMessageContext";
import "./RCChatWidgetMainView.css";
//import "../ChatWidgetMainView/ChatWidgetMainView.css"
import WebService from "../../service/webService";

class RCChatWidgetMainViewChatField extends React.Component {
    constructor() {
        super();
        this.state = {
            message: "",
            file: null,
            fileType: null,
            uploading: false
        };
        this.WebService = new WebService();
        this.textArea = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.message) {
            this.textArea.current.style.height = "18px";
            this.textArea.current.style.overflow = "hidden";
        } else {
            this.textArea.current.style.overflow = "auto";
        }
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            this.sendMessage();
        }
    };

    handleChange = (e) => {
        const message = e.target.value;
        this.setState({ message });
        this.props.handleUserTyping();
        e.target.style.height = "21px";
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    handleFileChange = (e) => {
        let file = e.target.files[0];
        let fileType = "";
        const uploadedFileType = file.type.split("/")[1];

        if (file && AllowedFileType.includes(uploadedFileType)) {
            if (AllowedImageType.includes(uploadedFileType)) {
                fileType = MediaType.IMAGE;
                let reader = new FileReader();
                reader.onload = (e) => {
                    let imagePreview = e.target.result;
                    this.props.onFileChange({ file, imagePreview, fileType });
                };
                reader.readAsDataURL(file);
            } else {
                fileType = MediaType.DOCUMENT;
                this.props.onFileChange({ file, fileType });
            }
            this.setState({ file, fileType });
        } else {
            fileType = MediaType.UNSUPPORTED;
            this.props.onFileChange({ file, fileType });
        }
        if (e && e.target && e.target.value) {
            e.target.value = null;
        }
        this.textArea.current.focus();
    };

    clearSelectedFile = () => {
        this.setState({ file: null, fileType: null });
    };

    canSendMessage = () => {
        const { message, file } = this.state;
        return (message && !!message.trim().length) || file;
    };

    sendMessage = async (e) => {
        e && e.preventDefault();
        const { message, uploading } = this.state;

        if (uploading) {
            return;
        }
        if (this.state.file) {
            try {
                this.setState({ uploading: true });
                this.props.onFileChange({ imageUploading: true });
                let responseData = await this.WebService.uploadMedia(this.state.file);

                if (!responseData.Errors) {
                    this.context.IframePostMessage({
                        Message: PostMessageType.SendMessage,
                        Data: { responseData, file: this.state.file, message: message || "" }
                    });
                    this.setState({ message: "" });

                    this.setState({ file: null, fileType: null, uploading: false });
                    this.props.onFileChange({
                        file: null,
                        fileType: null,
                        imagePreview: null,
                        imageUploading: false
                    });
                    if (this.props.showAutomationForm) {
                        this.props.handleShowOptionsContainer();
                    }
                } else {
                    this.setState({ fileType: MediaType.UNSUPPORTED, uploading: false });
                    this.props.onFileChange({
                        fileType: MediaType.UNSUPPORTED,
                        imageUploading: false
                    });
                }
            } catch (e) {
                console.log("err", e);
            }
            //  finally {
            //     this.setState({ file: null, fileType: null, uploading: false });
            //     this.props.onFileChange({
            //         file: null,
            //         fileType: null,
            //         imagePreview: null,
            //         imageUploading: false
            //     });
            // }
        } else if (!!message.trim().length) {
            this.context.IframePostMessage({
                Message: PostMessageType.SendMessage,
                Data: message
            });
            this.setState({ message: "" });
            if (!this.state.file && this.props.handleImageClearClick) {
                this.props.handleImageClearClick();
            }

            if (this.props.showAutomationForm) {
                this.props.handleShowOptionsContainer();
            }
        }
    };

    render() {
        return (
        <>
            <div className="chat-box-field-body">
                <div>
                    <form className="chat-box-input " onSubmit={this.sendMessage}>
                        {/* <input
                            type="text"
                            ref={this.textArea}
                            onChange={this.handleChange}
                            value={this.state.message}
                            placeholder="Type your message..."
                            disabled={this.state.uploading || this.props.showOptionsContainer}
                        ></input> */}

                        <textarea
                            ref={this.textArea}
                            onChange={this.handleChange}
                            value={this.state.message}
                            placeholder={(this.props.showOptionsContainer && !this.props.automationFormSubmitEver) || this.state.uploading ? "" : "Type your message..."}
                            disabled={this.state.uploading || (this.props.showOptionsContainer && !this.props.automationFormSubmitEver)}
                            rows={3}
                            style={{ maxHeight: "50px", height: "18px", backgroundColor:"#ffff"}}
                            onKeyDown={this.handleKeyDown}
                        />

                        <div className="actions">
                            <div className="chat-attachment action-btn margin-right add">
                                <span>
                                    <input
                                        id="fileUpload"
                                        type="file"
                                        onChange={this.handleFileChange}
                                        hidden
                                        disabled={
                                            this.state.uploading || this.props.showOptionsContainer
                                        }
                                    />
                                    <label htmlFor="fileUpload">
                                        <MdOutlineAttachFile />
                                    </label>
                                </span>
                            </div>
                            <button
                                type="submit"
                                className={`chat-send action-btn send ${
                                    this.canSendMessage() ? "allowed" : ""
                                }`}
                                disabled={this.props.showOptionsContainer && !this.props.automationFormSubmitEver}
                            >
                                <MdSend
                                    color={
                                        this.canSendMessage()
                                            ? this.context.widgetSettings.ButtonColor
                                            : null
                                    }
                                />
                            </button>
                        </div>
                        {/* {this.props.renderUploadedFile()} */}
                    </form>
                </div>
            </div>
             <div><p className={`typing-hint`}>{this.props.agentTyping ? "Agent is typing..." : ""}</p></div>
             </>
        );
    }
}

RCChatWidgetMainViewChatField.contextType = PostMessageContext;

export default RCChatWidgetMainViewChatField;

