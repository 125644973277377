import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";

import { RCChatWidgetMainView } from "./views/RCChatWidgetMainView";
import { getParams } from "./utils/commonUtil";

class App extends React.Component {

    constructor() {
        super()
        this.state = {
            isWidgetIntegrate: false,
        }
    }

    


    componentDidMount() {
        let params = getParams();
        if (params) {
            const isWidgetIntegrate = params["widgetIntegrate"];
            if (isWidgetIntegrate) {
                this.setState({ isWidgetIntegrate: isWidgetIntegrate })
            }
        }
    }

    render() {
        return (
            <Router>
                <div id="swift-sales-widget" className={this.state.isWidgetIntegrate ? "swift-sales-widget-integrate" : ""}>
                    <RCChatWidgetMainView />
                </div>
            </Router>
        );
    }
}

export default App;
